import React from 'react'
import * as Chakra from '@chakra-ui/react'
import {responsivePixel} from "./responsive-px";
import {SubtitleText} from "./text/subtitle-text";
import {HiCheckCircle} from "react-icons/hi";

const DarkButton = ({ noArrow, isSubmitting, hasSubmitted, ...props }) => {
  return (
    <Chakra.Button
      pt={responsivePixel(25)}
      pb={responsivePixel(20)}
      px={responsivePixel(30)}
      borderRadius="14px"
      bg={hasSubmitted ? "#48cb8e" : "#CB4848"}
      variant="solid"
      color="#fff"
      fontWeight="400"
      shadow="base"
      _after={(!isSubmitting && !hasSubmitted && !noArrow) && {
        content: '"\\2192"',
        position: 'absolute',
        opacity: 0,
        right: "30px",
        transition: "0.5s"
      }}
      css={{
          "span": {
              transition: "0.5s"
          }
      }}
      _active={(!isSubmitting && !hasSubmitted) && { bg: '#CB4848' }}
      _focus={(!isSubmitting && !hasSubmitted) && { bg: '#8e3232' }}
      _hover={(!isSubmitting && !hasSubmitted) && {
          bg: '#a23a3a',
          "span": {
              marginLeft: noArrow ? "0" : "-8px",
              marginRight: noArrow ? "0" : "8px"
          },
          "&:after": {
              opacity: 1,
              right: noArrow ? "0" : "20px"
          }
      }}

      {...props}
    >
      <SubtitleText as="span" scale={props.scale ? props.scale : 1}>
            {(!isSubmitting && !hasSubmitted) && (props.children)}
          {(isSubmitting && !hasSubmitted) && <Chakra.Spinner/>}
          {hasSubmitted && <Chakra.Box position="absolute" fontSize="20px" top="0" left="0" width="100%" height="100%" display="flex" justifyContent="center" alignItems="center"><HiCheckCircle/></Chakra.Box>}
      </SubtitleText>
    </Chakra.Button>
  )
}
export default DarkButton
