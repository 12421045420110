import React from 'react'
import * as Chakra from '@chakra-ui/react'
import { PageProps } from "gatsby";
import 'keen-slider/keen-slider.min.css'
import PageTransition from '../components/page-transition'
import Navbar from '../components/navbar'
import ContactModal from '../components/contact-modal'
import './style.css'

export default function ({ children, location }: PageProps) {

    // @ts-ignore
    global.baseUrl = "/.netlify/functions/base"

  return (
    <Chakra.Flex minH="101vh" w="100%" flexDirection="column">
      <Navbar />
      <PageTransition location={location}>
        <Chakra.Box w="100%">
          {children}
        </Chakra.Box>
      </PageTransition>
      {/*<Footer />*/}

      {/* MODALS */}
      <ContactModal />
    </Chakra.Flex>
  )
}
