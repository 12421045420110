import { useState, useEffect } from 'react'

export default function useScroll() {
  const [lastScrollTop, setLastScrollTop] = useState<number>(0)
  const [bodyOffset, setBodyOffset] = useState<any>(
    typeof window === 'undefined' || !window.document
      ? 0
      : document.body.getBoundingClientRect()
  )
  const [scrollY, setScrollY] = useState<number>(bodyOffset.top)
  const [scrollX, setScrollX] = useState<number>(
    bodyOffset.left
  )
  const [
    scrollDirection,
    setScrollDirection,
  ] = useState<string>()

  const listener = e => {
    setBodyOffset(
      typeof window === 'undefined' || !window.document
        ? 0
        : document.body.getBoundingClientRect()
    )
    setScrollY(-bodyOffset.top)
    setScrollX(bodyOffset.left)
    setScrollDirection(
      lastScrollTop > -bodyOffset.top ? 'down' : 'up'
    )
    setLastScrollTop(-bodyOffset.top)
  }

  useEffect(() => {
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  })

  return {
    scrollY,
    scrollX,
    scrollDirection,
  }
}