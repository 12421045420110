import * as Chakra from "@chakra-ui/react";
import React from "react";

interface Props extends Chakra.TextProps {
    scale?: number
}

export const HeadingText = ({scale = 1, ...props}: Props) => {

    let fontSize = 42;
    let letterSpacing = 1;
    let lineHeight = 40.5;
    let factor = 1;

    if (scale) {
        factor = fontSize / (fontSize / scale);
    }

    return (
        <Chakra.Heading
            width="100%"
            textAlign={{base: "center", lg: "left"}}
            fontFamily="heading"
            /*sx={{fontVariant: "small-caps"}}*/
            fontWeight="400"
            fontSize={{base: `${fontSize * factor * 0.8}px`, md: `${fontSize * factor * 0.9}px`,  lg: `${fontSize * factor}px`, xl: `${fontSize * factor * 1.1}px`}}
            lineHeight={{base: `${lineHeight * factor * 0.8}px`, md: `${lineHeight * factor * 0.9}px`,  lg: `${lineHeight * factor}px`, xl: `${lineHeight * factor * 1.1}px`}}
            letterSpacing={{base: `-${letterSpacing * factor * 0.8}px`, md: `-${letterSpacing * factor * 0.9}px`,  lg: `-${letterSpacing * factor}px`, xl: `-${letterSpacing * factor * 1.1}px`}}
            {...props}
        />
    )
}
