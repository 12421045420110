import React from 'react'
import * as Chakra from '@chakra-ui/react'
import {responsivePixel} from "./responsive-px";


function NavbarLogo({ height, ...props }: Chakra.BoxProps) {
  return (
      <Chakra.Box position="relative" width={responsivePixel(height)}
                  _before={{
                      content: '"Penelope"',
                      position: "absolute",
                      color: "#fff",
                      fontFamily: "body-alt",
                      lineHeight: "1.7",
                      fontVariant: "small-caps",
                      transform: 'translateX(-108%)',
                      top: 0,
                      fontSize: responsivePixel(24),
                      letterSpacing: responsivePixel(3)
                  }}
                  _after={{
                      content: '"Institute"',
                      position: "absolute",
                      color: "#fff", fontFamily: "body-alt",
                      fontVariant: "small-caps",
                      transform: `translateX(8%)`,
                      top: 0,
                      fontSize: responsivePixel(24),
                      letterSpacing: responsivePixel(3),
                      lineHeight: "1.7",
                  }}>

          <Chakra.Box as="svg" h={responsivePixel(height)} display="inline-block" viewBox="0 0 39 39" xmlns="http://www.w3.org/2000/svg" {...props}>
              <g transform="matrix(1.62434, 0, 0, 1.62434, -333.493256, -263.55719)">
                  <g fill="#FFF" transform="translate(221.319 170.255)">
                      <path d="M-8 -8h14c0.4445 0 1.1443 -0.0141 1.5792 0.4208c0.4349 0.4349 0.4208 1.1347 0.4208 1.5792v14h-16z"/>
                      <path d="M-8 -8h14c0.4445 0 1.1443 -0.0141 1.5792 0.4208c0.4349 0.4349 0.4208 1.1347 0.4208 1.5792v14h-16z" transform="rotate(90 -4 4)"/>
                      <path d="M-8 -8h14c0.4445 0 1.1443 -0.0141 1.5792 0.4208c0.4349 0.4349 0.4208 1.1347 0.4208 1.5792v14h-16z" transform="rotate(180 -4 4)"/>
                      <path d="M-8 -8h14c0.4445 0 1.1443 -0.0141 1.5792 0.4208c0.4349 0.4349 0.4208 1.1347 0.4208 1.5792v14h-16z" transform="rotate(270 -4 4)"/>
                      <path d="M-8 -8h14c0.4445 0 1.1443 -0.0141 1.5792 0.4208c0.4349 0.4349 0.4208 1.1347 0.4208 1.5792v14h-16z"/>
                      <path d="M-8 -8h14c0.4445 0 1.1443 -0.0141 1.5792 0.4208c0.4349 0.4349 0.4208 1.1347 0.4208 1.5792v14h-16z" transform="rotate(90 -4 4)"/>
                      <path d="M-8 -8h14c0.4445 0 1.1443 -0.0141 1.5792 0.4208c0.4349 0.4349 0.4208 1.1347 0.4208 1.5792v14h-16z" transform="rotate(180 -4 4)"/>
                      <path d="M-8 -8h14c0.4445 0 1.1443 -0.0141 1.5792 0.4208c0.4349 0.4349 0.4208 1.1347 0.4208 1.5792v14h-16z" transform="rotate(-90 -4 4)"/>
                  </g>
                  <path d="M 220.319 166.255 L 220.746 166.255 C 223.759 166.255 225.266 168.455 225.266 170.255 C 225.266 172.055 223.779 174.255 220.746 174.255 L 220.319 174.255 M 220.319 182.255 L 222.319 182.255" fill="none" strokeWidth="4" stroke="#21375C"/>
                  <path fill="#21375C" d="M 207.317 162.258 L 211.327 162.258 L 211.327 171.241 L 207.317 171.241 Z M 205.324 164.267 L 206.313 164.267 L 206.313 168.261 L 205.324 168.261 Z M 205.314 172.26 L 214.341 172.26 L 214.341 176.259 L 205.314 176.259 Z M 205.31 180.263 L 206.313 180.263 L 206.313 184.258 L 205.31 184.258 Z M 207.342 177.26 L 211.317 177.26 L 211.317 186.261 L 207.342 186.261 Z M 212.315 180.25 L 222.298 180.25 L 222.298 184.228 L 212.315 184.228 Z M 215.332 185.266 L 219.297 185.266 L 219.297 186.254 L 215.332 186.254 Z M 215.326 169.271 L 219.313 169.271 L 219.313 179.237 L 215.326 179.237 Z M 215.331 162.27 L 219.296 162.27 L 219.296 163.258 L 215.331 163.258 Z M 212.326 164.255 L 221.057 164.255 L 221.057 168.254 L 212.326 168.254 Z"/>
              </g>
          </Chakra.Box>
      </Chakra.Box>

  )
}

export default NavbarLogo
