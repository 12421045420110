import React from 'react'
import * as Chakra from '@chakra-ui/react'
import NavbarLink from './navbar-link'
import { useLocation } from "@reach/router"

interface Props extends Chakra.FlexProps {
  onClick?: any,
}

const NavbarLinks = ({ onClick, ...props }: Props) => {

  return (
    <Chakra.SimpleGrid
      letterSpacing="0.1em"
      textTransform="uppercase"
      textStyle="body"
      m="auto"
      spacing={{base: "32px", lg: "100px" }}
      {...props}
    >
      <NavbarLink onClick={onClick} to="/">
        Home
      </NavbarLink>
      <NavbarLink onClick={onClick} to="/">
        Why it’s important
      </NavbarLink>
      <NavbarLink onClick={onClick} to="/directory/">
        Meet the educators
      </NavbarLink>
      <NavbarLink
        onClick={onClick}
        style={{
          mr: { lg: '0' },
        }}
        to="/learn/"
      >
        Learn
      </NavbarLink>
    </Chakra.SimpleGrid>
  )
}
export default NavbarLinks
