import React from "react";

function EllipseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="4"
      fill="none"
      viewBox="0 0 4 4"
      style={{display:'inline', marginBottom:2}}
    >
      <circle cx="2" cy="2" r="2" fill="#9F9F9F"></circle>
    </svg>
  );
}

export default EllipseIcon;
