import React from 'react'
import * as Chakra from '@chakra-ui/react'
import {BodyText} from "./text/body-text";
import CheckmarkIcon from "./checkmark-icon";
import { responsivePixel } from "./responsive-px";


interface Props extends Chakra.InputProps {
  label: string,
  placeholder: string,
  type: string,
    hasError?: any,
    isHoneypot?: boolean
}

const ContactModalInput = React.forwardRef<any, Props>(({ label, placeholder, type, hasError, isHoneypot = false, onChange, onBlur, name }, ref) => {


  return (
    <Chakra.FormControl display="flex" alignItems="center" opacity={isHoneypot ? "0" : "1"} height={isHoneypot ? "0" : "auto"} position={isHoneypot ? "absolute" : "relative"} width={isHoneypot ? "2px" : "100%"} zIndex={isHoneypot ? "-1" : undefined}>
      <Chakra.FormLabel
          as={BodyText}
          scale={0.7}
        pl="4px"
        color="#000"
        width={responsivePixel(120)}
        pr="10px"
      >
        {label}:
      </Chakra.FormLabel>
      <Chakra.InputGroup>
          {type !== "textarea" ? <Chakra.Input
                 ref={ref}
                 name={name} onChange={onChange} onBlur={onBlur}
              borderRadius="12px"
              bg="#F1F1F1"
              p="14px"
              fontSize="14px"
              color="#333333"
              _placeholder={{ color: '#BDBDBD' }}
              variant="unstyled"
              type={type}
              placeholder={placeholder}
              transition="0.3s"
              border={hasError ? "1px solid red" : "1px solid transparent"}

          /> :
              <Chakra.Textarea
                  ref={ref}
                  name={name} onChange={onChange} onBlur={onBlur}
                  borderBottom="1px solid #BDBDBD"
                  p="14px"
                  fontSize="14px"
                  borderRadius="12px"
                  color="#333333"
                  bg="#F1F1F1"
                  _placeholder={{ color: '#BDBDBD' }}
                  variant="unstyled"
                  placeholder={placeholder}
                  transition="0.3s"
                  border={hasError ? "1px solid red" : "1px solid transparent"}
              /> }
          {/*type !==  "textarea" && !hasError && <Chakra.InputRightElement paddingRight="20px" height="100%" children={<CheckmarkIcon />} />*/}
      </Chakra.InputGroup>
    </Chakra.FormControl>
  )
})

export default ContactModalInput
