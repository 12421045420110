export function responsivePixel(size) {

    size = size.toString()
    size = size.replace("px", "")
    size = parseInt(size);

    return {base: `${size * 0.8}px`, md: `${size * 0.9}px`,  lg: `${size}px`, xl: `${size * 1.1}px`}


}
