import React, {useState} from 'react'
import * as Chakra from '@chakra-ui/react'
import NavbarLogo from './navbar-logo'
import HamburgerIcon from './hamburger-icon'
import DarkButton from './dark-button'
import NavbarLinks from './navbar-links'
import { Link } from 'gatsby'
import {UIState} from "./ui-state";
import useScroll from "../hooks/useScroll";


const Navbar = () => {
  // Have to check if viewport can show drawer
  const isMobileView = Chakra.useBreakpointValue({
    base: true,
    lg: false,
  })

  const { scrollY, scrollDirection } = useScroll()

  const {
    isOpen,
    onOpen,
    onClose,
  } = Chakra.useDisclosure()

  return (
    <>
      <Chakra.Box position="absolute" w="100%" h={{base: '55px', md: '70px', lg: '100px'}} bg="#21375C" />
      <Chakra.Box
        zIndex="3"
        bg="#21375C"
        w="100%"
        pos="sticky"
        transition="0.4s"
        top="0"
        className={(scrollY > 50 && scrollDirection !== "down") ? "scrolled" : undefined}
        css={{
          "&.scrolled": {
            transform: "translateY(-100%)"
          },
        }}
      >
        <Chakra.Box
          maxW="1538px"
          w="100%"
          margin="0 auto"
        >
          <Chakra.Flex pos="absolute" width="100%" alignItems="center" justifyContent="center" left="0" top="0" height="100%">
            <Link to="/home/">
              <NavbarLogo height="40px"/>
            </Link>
          </Chakra.Flex>

          <Chakra.Flex
            h={{base: '55px', md: '70px', lg: '100px'}}
            alignItems="center"
            w="100%"
            justify="flex-end"
            p="16px 25px 16px 0"
            pl={{ base: '22px', md: '32px' }}
          >

            <DarkButton d={{ base: 'none', lg: 'inherit' }} onClick={UIState.onOpenSignupModal}>
              Get Started
            </DarkButton>
              {/*<Chakra.Box onClick={onOpen} cursor="pointer" d={{ base: 'block', lg: 'none' }}>
              <HamburgerIcon />
            </Chakra.Box>*/}
          </Chakra.Flex>
        </Chakra.Box>
      </Chakra.Box>

      {/*<Chakra.Box
        mt={{ base: '55px', md: '70px', lg: '145px' }}
        bg="#21375C"
        display="flex"
      >
        <NavbarLinks
          onClick=''
          d={{ base: 'none', lg: 'flex' }}
          justifyContent="center"
          height="65px"
          alignItems="center"
        />
      </Chakra.Box>*/}

      {/* RESPONSIVE DRAWER */}

      <Chakra.Drawer
        placement="right"
        onClose={onClose}
        isOpen={isOpen && isMobileView}
        size="xl"
      >
        <Chakra.DrawerOverlay>
          <Chakra.DrawerContent>
            <Chakra.DrawerCloseButton />
            <Chakra.DrawerBody>
              {/*<Search mt="80px" />*/}
              <Chakra.Divider
                mt="12px"
                border-color="#fff"
                mb="60px"
                opacity="0.16"
              />
              <NavbarLinks
                onClick={onClose}
                style={{
                  pl: '15px',
                  flexDir: 'column',
                  justify: 'center',
                }}
              />

              <Chakra.Flex
                mb="47px"
                pos="absolute"
                bottom="0"
                left="0"
                right="0"
                justify="center"
              >
                <DarkButton
                  mx="15px"
                  minW="207px"
                  w="75%"
                  onClick={UIState.onOpenSignupModal}
                >
                  Get Started
                </DarkButton>
              </Chakra.Flex>
            </Chakra.DrawerBody>
          </Chakra.DrawerContent>
        </Chakra.DrawerOverlay>
      </Chakra.Drawer>
    </>
  )
}
export default Navbar
