import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  '2xl': '1650px',
})

const theme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        overflow: 'hidden',
        position: 'relative',
        transform: 'translate3d(0,0,0)',
        borderRadius: '10px',
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          width: 'auto',
          height: 'auto',
          pointerEvents: 'none',
          backgroundImage: 'radial-gradient(circle, #000 10%, transparent 10.01%)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50%',
          transform: 'scale(10,10)',
          opacity: 0,
          transition: 'transform .5s, opacity 1.5s'
        },
        '&:active:before': { transform: 'scale(0,0)', opacity: 0.1, transition: '0s' }
      }
    },
  },
  styles: {
    global: {
      html: {
        backgroundColor: "#CB4848"
      },
      ".droplet": {
        overflow: 'hidden',
        position: 'relative',
        transform: 'translate3d(0,0,0)',
        borderRadius: '10px 0 10px 0',
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          width: 'auto',
          height: 'auto',
          pointerEvents: 'none',
          backgroundImage: 'radial-gradient(circle, #000 10%, transparent 10.01%)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50%',
          transform: 'scale(10,10)',
          opacity: 0,
          transition: 'transform .5s, opacity 1.5s'
        },
        '&:active:before': { transform: 'scale(0,0)', opacity: 0.1, transition: '0s' }
      }
    }
  },
  textStyles: {
    heading: {
      fontFamily: 'heading'
    },
    body: {
      fontFamily: 'body',
    },
    bodyAlt: {
      fontFamily: 'body-alt'
    },
    subTitle: {
      fontFamily: 'subtitle'
    }
  },
  fonts: {
    heading: 'heading',
    body: 'body',
    bodyAlt: 'body-alt',
    subTitle: 'subtitle'
  },
  breakpoints,
})

export default theme
