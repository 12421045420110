import { GlobalState } from 'react-gstate'
import Hero from "../images/HeroImage.jpg"
import Hillary_Jade from "../images/Hillary_Jade.jpg";
import Jeffrey_Tsang from "../images/Jeffrey_Tsang.jpg";
import Noel_Jett from "../images/Noel_Jett.jpg";
import LeeAnn_Barlow from "../images/LeeAnn_Barlow.jpg";
import Leon_Conrad from "../images/Leon_Conrad.jpg";
import Susanne_Sklar from "../images/Susanne_Sklar.jpg";
import Dan_Ashlock from "../images/Dan_Ashlock.jpg";
import Wendy_Ashlock from "../images/Wendy_Ashlock.jpg";
import Lin_Lim from "../images/Lin_Lim.jpg";
import Course_Placeholder from "../images/BgLogo.svg";
import React from "react";

let pageData = {
    atfTitle: "Beyond All Boundaries",
    atfBody: <span>Is your highly/profoundly gifted child getting the individualized support they need?<br/><br/>Penelope Institute brings together a global team of gifted education experts to collaboratively design solutions as unique as your child’s journey.<br/><br/>You name it, we customize it.</span>,
    atfImage: Hero, // url or an import
    welcomeTitle: "Welcome",
    welcomeBody: <span>Highly and profoundly gifted (HG/PG) students have always been beyond: beyond the box, beyond the impossible, and beyond one-size-fits-all definitions. At Penelope Institute, our student Scholars pursue their areas of passion and untapped potential through highly customizable and adaptive, one-to-one and small group instruction with experts in their fields from around the world. Designed to serve the unserved, Penelope’s course offerings expand wherever HG and PG students’ imaginations go, with tailored content, inquiry, and support from our gifted Fellows&thinsp;—&thinsp;anywhere, and in any timezone.
<br/><br/>
Penelope takes students beyond gifted schools and enrichment programming, by giving students agency over what they learn and how they apply it. Unlimited by scope, funding, and accessibility, unfettered by emphasis on acceleration or differentiation, Penelope goes beyond traditional gifted programs to provide our Scholars what they want to learn, what they need to thrive emotionally, in a safe and inclusive community.
<br/><br/>
Join us beyond the boundaries of what you and your child have previously experienced in gifted education. We look forward to connecting with you!</span>,
    welcomeName: "Hillary Jade, MA MSc (she/her)",
    welcomeCredentials: "Program Director",
    welcomePhoto: Hillary_Jade,
    fellowsTitle: <span>Our&nbsp;Fellows</span>,
    fellowsBody: <span>Penelope Fellows are a highly collaborative team of subject-matter experts, every one with extensive experience serving the highly and profoundly gifted&thinsp;—&thinsp;many are themselves highly/profoundly gifted&thinsp;—&thinsp;as well as many years, even decades, of direct tutoring and mentoring experience, not just managing large classrooms.</span>,
fellowsSlides: [
    { image: Jeffrey_Tsang,
      title: "Jeffrey Tsang, PhD (they/he)",
      subtitle: "Mathematics · Computer Science · Game Theory · Music · Biology · Physics", text: "text",
      summary: "There are always more ways to answer a question, and more perspectives to see the world from. Instead of asking students for “the” answer, we count just how many there are and then invent a few more. Even the term “student” doesn’t capture the full picture; the learner is an equal partner, and the journey to integrate new facets and branches into their tree of knowledge is their own. As mentor, I am there as a flashlight down the rabbit holes, an atlas for mapping all the hidden connections, and a safety net when they “fail” and find a new direction.",
      expandedParagraph: "Jeffrey Tsang earned their PhD in applied math at age 23. They were a quantitative researcher at Jane Street, a Wall Street trading firm, and are now a senior software engineer at Google, working on AI features for Gmail. Jeffrey also has a bachelor’s in computer science, minors in biology and physics, a music diploma in piano, and two black belts in taekwondo. They represented Canada in the World Junior Bridge Championships and summited Mt. Aconcagua, Argentina (6962m). After serving as Finance Officer for SENG, they founded the Penelope Institute to truly serve the highly/profoundly gifted.",
        objectPosition: "center 31%"
    },
    { image: Noel_Jett,
      title: "Noel Jett, PhD (her/they)",
      subtitle: "Psychology · Social Sciences · Film", text: "text",
      summary: "I have worked with gifted students all my life and I have found so much joy and creativity in this weird little world.  I firmly believe that every student has unique strengths, as well as personal needs, that should be adapted for and supported.  So many gifted students long to understand the deeper meanings and connections behind the information they’re given, and my favorite thing to do is to follow a student down the path they want to take through learning.  I really do think education can save the world!",
      expandedParagraph: "Noel Jett completed her Doctorate in Gifted and Talented Education in 2018 when she was 19 years old; her dissertation was the first research study ever on radically early college entrance.  Ever since she has been teaching and researching as well as finishing a Master’s in Clinical Mental Health Counseling (completed 2021).  Now, she is pursuing a greater understanding of the counseling needs of the gifted and their families.  In her limited spare time she is a writer and pianist, as well as a hobby-level linguist and martial artist.",
        objectPosition: "center 20%"
    },
    { image: LeeAnn_Barlow,
      title: "LeeAnn Barlow, PhD (she/her)",
      subtitle: "Mathematics · Biology · Chemistry · Physics", text: "text",
      summary: "Teaching is never one-size-fits-all. As a gifted educator with experience working with students with multiple exceptionalities, confidence issues, and those who struggle with traditional learning environments, I’ve seen the difference the right teacher can make. When I teach, I strive to enrich the whole student and meet them at their level. Every student brings their own strengths to the table, and it’s my job to help students use those strengths in new ways. I love working with students who feel intimidated by math and science and those seeking to connect their learning to the bigger picture.",
      expandedParagraph: "LeeAnn Barlow earned her PhD in mathematics at the age of 23. She briefly worked in tech development as a data analyst and UI specialist before returning to her first love of teaching. In 2018, she founded Mathemagical, a private tutoring company focused on math and science education for all levels. LeeAnn also holds a BSc in mathematics and is completing a diploma in horticulture with a specialization in sustainable urban agriculture. When she’s not working, she can be found puttering around her garden, writing, or creating eco-friendly handmade products for her side business, Eeko Handmade.",
        objectPosition: "center 10%"
    },
    { image: Leon_Conrad,
      title: "Leon Conrad, MA GTCL (he/him)",
      subtitle: "Classical Liberal Arts · Writing · Classical Logic · Traditional Geometry · Music", text: "text",
      summary: "My tutoring is informed by an integrated approach to the classical Liberal Arts. My approach to tutoring both intellectually challenged and gifted and talented students draws on this but is also inspired by the work of Reuven Feuerstein, who devised the Instrumental Enrichment programme, and George Spencer-Brown, author of “Laws of Form”. I also draw on techniques used by traditional storytellers, which typically encourage a holistic use of the brain, and involve integrating image-making, word creation, and movement as part of a unified act of communication, based on the innate understanding of story structures.",
      expandedParagraph: "Leon Conrad started working as a musician at the age of 11 and went on to work as a vocal coach and repetiteur, founding the Conrad Voice Consultancy in 1998, the first specialist presentation skills consultancy in the UK to specialise in voice training. His interest in historic needlework design led him to pursue an MA in the History of Design and Material Culture of the Renaissance, rewriting the history of English embroidered bookbindings of the 16th and 17th centuries. His current research draws on his interest in living traditions of storytelling to explore new ways of mapping the structures through which story unfolds within us.",
        objectPosition: "center 33%"
    },
    { image: Susanne_Sklar,
      title: "Susanne Sklar, DPhil (she/her)",
      subtitle: "Literature · Great Books · Mythology and Religion", text: "text",
      summary: <span>“Education is not about the filling of a pail;<br/>
      it’s about the lighting of a fire”.<br/>
<br/>
Learning shapes life; sculpting the quality of each day can bring joy, not only to yourself but to many. Thinking in questions and responding creatively to great books and ideas allows you to stand on the shoulders of giants, enlarging your worldview.<br/>
<br/>
      Most of my classes (even in Oxford!) involve project learning: using performance, music, art, and creative as well as critical writing to enter into the world of a text, an epoch, or an idea.</span>,
      expandedParagraph: "Susanne Sklar has been teaching humanities throughout the world since 1984. She currently teaches at Oxford, and has taught in China, Russia, Sweden, at Northwestern University and Shimer College in the USA, as well as in London.  She’s published many articles about the work of William Blake as well as a book “Blake’s ‘Jerusalem’ As Visionary Theatre” (OUP). Recently she finished a historical novel, “The Magdalene’s World”. Additionally, Susanne has been a peace activist and researcher, and an imaginative social worker, creating arts programs for Chicago teenagers who'd been homeless and abused.  Susanne loves teaching!",
        objectPosition: "center center"
    },
    { image: Dan_Ashlock,
      title: "Daniel Ashlock, PhD (he/him)",
      subtitle: "Mathematics · Game Design · Computational Biology · Science Fiction and Fantasy", text: "text",
      summary: "My philosophy for gifted education is based on the observation that there are far more connections between subjects than there seem to be at first glance. Mastering specific skills is a good initial goal, but observing how and where these skills apply and have sister skills in other fields is both interesting and useful in its own right; it also serves as a context and anchor, so that the skills are retained. I am a mathematician and this has made me a far more effective programmer, for example, because the mental discipline of mathematics decreases the number of coding errors I make.",
      expandedParagraph: "Daniel Ashlock is a professor of mathematics with a doctorate from Caltech. His current research focus is in bioinformatics and computational intelligence, and he publishes in both fields. Professor Ashlock has been teaching mathematics for over thirty years, including mentoring gifted students. He has authored or co-authored ten textbooks and has developed university courses in bioinformatics, computational intelligence, discrete mathematics, and calculus. He has won multiple awards for teaching excellence, research excellence, and advising students, and has published over 100 peer-reviewed articles with student co-authors. He is an experienced, gifted mentor and parent.",
        objectPosition: "center center"
    },
    { image: Wendy_Ashlock,
      title: "Wendy Ashlock, PhD (she/her)",
      subtitle: "Mathematics · Computer Science · Critical Thinking · Philosophy · Literature", text: "text",
      summary: "Teaching occurs on a spectrum from teaching a skill to nurturing growth. At one end, the student often is asked to do exercises that seem pointless, like memorizing multiplication tables when the answer is readily available on a calculator. The teacher sets the student up for future success. At the other end, often the student surpasses the teacher in skill. For this sort of learning, the teacher’s expertise is in how best to make progress. I like teaching all along this spectrum, but find the most joy in teaching at the far end, helping my students learn to learn.",
      expandedParagraph: "Wendy Ashlock was raised in a house full of books and philosophical conversations, and educated at the University of Chicago, a university that prioritizes liberal education. She worked for many years as an educational software developer. She raised three gifted children and has tutored people of all ages. She was a Suzuki violin parent, ran a Great Books discussion group for retirees, and taught university classes. Her Master's degree is in computational intelligence, and her PhD is in bioinformatics. She is passionate about nuitrition and remedying discrimination against women in health research.",
        objectPosition: "center 19%"
    },
    { image: Lin_Lim,
      title: "Lin Lim, PhD (she/her)",
      subtitle: "Psychology · Gifted Education · Sociology · Economics", text: "text",
      summary: "Teaching is guiding through a journey of dynamic, complex, systemic interactions between immediate stakeholders (educators, students, families) and other environments such as the physical, social, and psychological. Teaching is a bi-directional relationship for “learning in development”. Thus, assessing a good fit between my natural range of teaching/guiding style and any potential student forms a meaningful foundation towards success, growth, and development.",
      expandedParagraph: "Lin Lim has an eclectic educational background including economics, environmental studies, education, and psychology. She is currently an Associate Dean of Media and Public Relations at Bridges Graduate School of Cognitive Diversity in Education. She holds a doctorate in psychology from Boston University, an Academic Graduate Certificate in Mind, Brain and Education (Johns Hopkins University), and Twice-exceptional Education (Bridges Graduate School). She is a founding board member of the Texas non-profit Gifted Education Family Network and volunteers in various education related non-profits. Her current area of interest is in interdisciplinary frameworks for human development and education.",
        objectPosition: "center 28%"
    },
],
    coursesTitle: <span>Our&nbsp;Courses</span>,
    coursesBody: "Penelope Institute offers rigorous, interdisciplinary, and individualized study across the Arts, Sciences, and Humanities. Note that our courses are not offered by specific faculty; our entire team is collaborative and every course draws on our combined centuries of total gifted, teaching, and mentoring experience.",
    courses: [
    {
        title: "Advanced Counting",
        subtitle: "Mathematics",
        summary: <span>This course introduces the discipline of enumeration: how to count things.  This is a part of the area of mathematics called combinatorics.  The course will introduce counting techniques from independent and mutually exclusive events to ordering problems, choice problems, and advanced techniques for counting symmetries and determining the number of distinct designs in a design space.  Students will be given individual projects to work on throughout the course and the course will conclude with presentations of students’ findings.<br/><br/>Duration: up to 12 sessions<br/>Content: upper elementary and above</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "The Bible",
        subtitle: "Literature · Mythology and Religion",
        summary: <span>We’ll look carefully at Genesis, considering family tensions, the nature of God, angels, fake news, sex and the position of women, violence, trickery, consequences&thinsp;—&thinsp;and forgiveness.  You’ll be asked to imagine being a character in each of the readings we discuss.  What does Abram want?  What does Rebecca dislike?  How does Esau change?  You’ll be asked to question each reading, to bring many questions to class.<br/>
            <br/>
            Duration: up to 6 sessions<br/>
            Content: upper secondary/high school and above</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "Cosmology",
        subtitle: "Physics · History",
        summary: <span>This course reviews the history of our beliefs about the structure of the universe, from ancient times to modern.  The Builder of Stonehenge, Ancient Egypt, Classical China, and Classical Greece all had different views of the structure of the universe.  The European view of the structure of the universe kept evolving from the Ptolemaic belief that earth was the center of the universe, through the Copernican revolution, and on to Kepler’s Laws of Planetary motion. We conclude with the modern view including relativity, the big bang, and the expanding universe.<br/><br/>Duration: up to 8 sessions<br/>Content: upper elementary and above</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "Evolution and Biological Diversity",
        subtitle: "Biology",
        summary: <span>This course will review the historical development of the theory of evolution from Darwin’s voyage on the Beagle to the modern version of the theory.  The origin of biological diversity in evolution and its value for the health of ecosystems and economies will be covered.  The course will touch on the controversy of the creation-evolution debate.  The biological and economic  implications of the theory from food security to disease management will be covered.<br/><br/>Duration: up to 8 sessions<br/>Content: upper secondary/high school and above</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "Gifted Support Group",
        subtitle: "Socio-emotional",
        summary: <span>This course is intended to introduce the gifted to peers in a moderated setting. It is a peer support group, not therapy.  Discussions will be about problems encountered at school and with peers and parents connected to being gifted.  Topics of interest may be explored and students will learn from one another, with guidance from the instructor, strategies for living while gifted.<br/>
            <br/>
            Duration: as needed<br/>
            Ages: relatively similar range in the group</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "Critical Thinking",
        subtitle: "Integrated Knowledge · Logic",
        summary: <span>(For lower elementary): This course is an introduction to critical thinking for children who can read but are not yet using social media. Topics would include: advertising, the difference between a story and a lie, figurative language (Amelia Bedelia), math and logic problems with multiple possible solutions, and social problem solving.<br/>
<br/>
(For upper elementary): This course is for children who are just beginning to get a lot of their information from the internet. Topics would include: multiple points of view, looking for sources of bias, how to tell the difference between news and advertising, problem solving.<br/>
<br/>
            (For secondary/middle school and above): This course is for children who make heavy use of the internet, both for social purposes and for getting information. Topics would include: how to find the source of an internet post, problems associated with anonymity, evaluating the quality of information, how to identify bias, problem solving using multiple methods, identifying social bubbles and problems with polarization.<br/>
            <br/>
            Duration: up to 6 meetings<br/>
            Content: varies</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "Game Theory",
        subtitle: "Economics · Sociology",
        summary: <span>How do we make decisions when we’re interacting with other people’s choices? This is the core of game theory, which allows us to mathematically study these situations. We look at basic concepts and tools such as dominance, mixed strategies, equilibria, and backward induction. We then apply these tools to solve various real-world examples, from psychology (human behaviour), to politics (collective human behaviour), to biology (animal behaviour), to AI (how to beat humans at games), as well as philosophical paradoxes.<br/>
            <br/>
            Duration: up to 6 meetings<br/>
            Content: upper elementary and above</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "Human Development: History, Theory and Critical Analysis",
        subtitle: "Integrated Knowledge · Sociology",
        summary: <span>This course introduces the history, major theories on human development, critical considerations and current ideas integrating interdisciplinary concepts from math, physics,  economics, and evolutionary biology  such as complex systems, chaos theory, game theory.  Students will be given individual projects to work on throughout the course, based on their interest within the broad topic of human development, including searching theories from other disciplines that can be applied towards a framework of understanding human development. The course will conclude with presentations of the students’ thoughts and findings.<br/>
            <br/>
            Duration: as research interest permits<br/>
            Content: upper secondary/high school and above</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "Evolved Art",
        subtitle: "Computing · Arts",
        summary: <span>This course will introduce the use of computers as high level artists’ tools, introducing algorithms for the creation of a variety of digital media in two dimensions.  The use of evolutionary algorithms, capable of searching enormous spaces of images for aesthetically pleasing examples, will be a central part of the course and introduce the field of search based procedural content generation.  Students will learn to effectively use content generation tools incorporating evolution to design pictures based on cellular automata, fractals, and parametric curves.  Students will submit several pieces of art, starting an artistic portfolio.<br/>
            <br/>
            Duration: up to 6 sessions<br/>
            Content: varies&thinsp;—&thinsp;elementary through undergraduate versions</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "King Lear",
        subtitle: "Theatre · Literature",
        summary: <span>King Lear is arguably Shakespeare’s greatest play.  Why is Lear so irascible?  What is wrong with him?  What makes his elder daughters particularly nasty?  Why is there so much suffering?  What makes a character evil?  We’ll closely read this great play, and you’ll be asked to memorize some lines, and write an imaginative history for the character/s you choose to play.  What is tragedy?<br/>
            <br/>
            Duration: up to 5 sessions<br/>
            Content: upper secondary/high school and above</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "Exploring Classical Chinese Literature",
        subtitle: "Literature",
        summary: <span>This course looks at a range of classical Chinese literature in translation (informed by engagement with primary source text using helpful on-line resources). Can a common structure be found to underpin these texts? How ubiquitous is it? And what can we learn from engaging with a close reading of this kind?
            The course draws on original research into the deep workings of story explored in a wider way in “The Unknown Storyteller”.<br/>
            <br/>
            Duration: up to 6 sessions<br/>
            Content: upper secondary/high school and above</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "Getting to Know the Unknown Storyteller: A Course for Writers",
        subtitle: "Literature",
        summary: <span>This course is an introduction to story structure for high achievers. Using a simple system of only six primary symbols, we learn to map the underlying pattern of story, mapping six common story structures (Quest, Trickster, Rags to Riches, Revelation, Call and Response, Ki-Sho-Ten-Ketsu) and learning about twelve further structures. Participants will learn how to map individual characters’ story lines, analyze how they intersect, how to create vivid descriptions of setting, of character, how to plan a story structure and how to use plot pattern and narratorial techniques to maximum effect to create a compelling narrative which has a clear purpose and message.<br/>
<br/>
            The course is suitable for learners who love language, enjoy getting to grips with the subtle differences between terms such as “between” and “among” and the joy of using words powerfully, wisely, and&thinsp;—&thinsp;most importantly&thinsp;—&thinsp;a sense of fun.
<br/>
            <br/>
            Duration: up to 10 sessions<br/>
            Content: varies&thinsp;—&thinsp;upper elementary through undergraduate versions</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "Introduction to Mathematical Proof",
        subtitle: "Mathematics · Logic",
        summary: <span>This course introduces students to mathematics as a creative endeavour. It gives them a chance to do mathematics the way mathematicians do. Students will be guided through examples of historically important beautiful proofs. They will be introduced to basic proof techniques: proof by contradiction, proof by induction, proof by construction. They will have the opportunity to do their own proofs, both on their own and collaboratively. The challenges given to students will be appropriate to the mathematical background they have.
<br/>
            <br/>
            Duration: up to 6 sessions<br/>
            Content: varies&thinsp;—&thinsp;upper elementary through undergraduate versions</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "Origins of Chemistry",
        subtitle: "Chemistry · History",
        summary: <span>The philosopher’s stone was a mystic token that could tell, by touch, if a person was in a state of grace and alchemy originated in attempts to find ways to induce a state of grace.  This gradually changed into a desire to find a more modern sort of reward: gold.  These efforts resulted in a good deal of useful work toward modern chemistry.  Chemistry also arose from fermentation, refining metals, baking, making glazes for pottery, and preserving food.  The course will cover historical topics and then continue to the ideas of atomic theory, compounds, up to and including the periodic table and its remarkable implications and consequences.
<br/>
            <br/>
            Duration: up to 8 sessions<br/>
            Content: upper elementary and above</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
    {
        title: "The Liberal Arts as “Lost Tools for Learning”",
        subtitle: "Integrated Knowledge",
        summary: <span>This course takes an in-depth look at the traditional liberal arts as an integrated and integrative organon (or dynamic system) for learning. It starts with the three word-based topics of Grammar, Logic, and Rhetoric and explores how they interconnect. It then looks at traditional aspects of the four number-based topics of traditional Geometry, Arithmetic and Number Theory, Musical Theory and Proportions, and historic approaches to Astronomy. The focus of this course is very much on reconnecting to key primary source texts - Aristotle’s ‘Categories’, the beginning of Thrax’s Grammar manual, Parmenides on Logic and Cicero and Quintillian on Rhetoric, as well as Plato, Boethius, and Proclus on Number with practical work based on the Starcut diagram, and an exploration of the rational and irrational qualities and ratios within it and how they relate to systems of ancient measure. Students will be asked to discuss and compare historic approaches with modern approaches and critically explore what has been lost and what has been gained over time with advances in modern science and technology.<br/><br/>
            Duration: up to 10 sessions<br/>
            Content: varies&thinsp;—&thinsp;upper elementary through undergraduate versions<br/>
            Requisites: Scholars will require a high quality drawing compass and standard stationery kit.</span>,
        image: Course_Placeholder,
        objectPosition: "center center"
    },
],
    whatWeOfferTitle: "What We Offer",
    whatWeOfferFeatures: [
    {
        image: Course_Placeholder,
        title: "Live interactivity",
        body: "Unlike many other remote learning platforms, Penelope’s highly interactive teaching methodology and live-streaming platform avoids dry, didactic teaching and pre-recorded lectures. Our Scholars interact collaboratively with Fellows and peers in a safe and integrated classroom environment, building bridges of communication that enhance knowledge.",
    },
    {
        image: Course_Placeholder,
        title: "Customization",
        body: "At Penelope Institute, your child is encouraged to forge their own educational path, working together with Fellows to set their own pace and customize the course of their own study. Whether choosing to work within small peer groups or one-on-one with our Fellows, students are thoughtfully matched with resources and peers that will help them actualize their educational goals.",
    },
    {
        image: Course_Placeholder,
        title: "Holistic",
        body: "Penelope Institute invests in continuously building competencies in our scholars that strengthen their ability to learn&thinsp;—&thinsp;and we do the same for our Fellows to strengthen their ability to teach. By devoting ourselves wholly to the social and emotional well-being of every part of our organization, we believe we are better preparing all individuals to successfully navigate the challenges of our ever-changing world.",
    }
],
    products: [
    {
        title: "Lightning Seminars",
        bullets: [
            "On-demand, scheduled when sufficient interest in a topic",
            "Rigorous content based on our faculty specialties",
            "May also propose any topic our faculty can collectively cover",
            "Any of our subject experts on the topic may present collaboratively",
            "Online, live interaction between all participants",
            "Safe environment sensitive to socio-emotional needs",
            "45-minute sessions",
            "Individual sessions",
            "Class size capped at 5",
            "100USD/session"
        ],
        image: Course_Placeholder
    },
    {
        title: "Small Group Classes",
        bullets: [
            "On-demand, scheduled whenever all Scholars and Fellows agree on a topic",
            "Rigorous content personalized to class interest",
            "May also propose any topic our faculty can collectively cover",
            "Any of our entire faculty may collaborate as appropriate for the current topic",
            "Online, live interaction between all participants",
            "Safe environment sensitive to socio-emotional needs",
            "Session length flexible, usually 30–60 min",
            "Course length flexible, usually 4–12 meetings",
            "Class size capped at 5",
            "100–200USD/hr, depending on size"
        ],
        image: Course_Placeholder
    },
    {
        title: "One-on-One Mentoring",
        bullets: [
            "On-demand, scheduled whenever Scholar and Fellows agree",
            "Rigorous, personally individualized content",
            "May also propose any topic or subject our faculty can collectively cover",
            "Any of our entire faculty may collaborate as appropriate for the current topic",
            "Online, live interaction",
            "Safe environment sensitive to socio-emotional needs",
            "Session length flexible",
            "Any number of sessions",
            "Personal instruction",
            "300USD/hr"
        ],
        image: Course_Placeholder
    }
],
    testimonialsTitle: "Testimonials",
    testimonials: [ // name, summary, quote
]
}


interface UIStateProps {
    showSignupModal: boolean,
    pageData: any,
    checkboxValues: any
}


class UIStateStore extends GlobalState<UIStateProps> {
    onOpenSignupModal() { UIState.setState({showSignupModal: true}) }
    onCloseSignupModal() { UIState.setState({showSignupModal: false}) }
    setCheckboxValues(e) {
        if (this.state.checkboxValues.find(x => x === e)) {
            let i = this.state.checkboxValues.indexOf(e);
            let newVals = JSON.parse(JSON.stringify(this.state.checkboxValues))
            newVals.splice(i, 1);
            UIState.setState({ checkboxValues: JSON.parse(JSON.stringify(newVals)) })
        } else {
            this.state.checkboxValues.push(e);
            UIState.setState({ checkboxValues: JSON.parse(JSON.stringify(this.state.checkboxValues)) })
        }
    }
    addCourseToModal(e) {
        if (!this.state.checkboxValues.find(x => x === e)) {
            this.state.checkboxValues.push(e);
            UIState.setState({ checkboxValues: JSON.parse(JSON.stringify(this.state.checkboxValues)) })
        }
        UIState.setState({showSignupModal: true})
    }
}

export const UIState = new UIStateStore({
    showSignupModal: false,
    pageData: pageData,
    checkboxValues: []
})
