import React, {useEffect} from 'react'
import * as Chakra from '@chakra-ui/react'
import { useForm } from 'react-hook-form';
import {APIRequest} from 'appsby/src/client';
import {UIState} from "./ui-state";
import {HeadingText} from "./text/heading-text";
import {responsivePixel} from "./responsive-px";
import {SubtitleText} from "./text/subtitle-text";
import DarkButton from "./dark-button";
import { navigate } from "@reach/router";
import ContactModalInput from "./contact-modal-input";
import { BodyText } from "./text/body-text";

const ContactModal = () => {

    const { register, handleSubmit, formState: { errors, isValid } } = useForm({mode: 'onChange', reValidateMode: 'onChange', shouldFocusError: true});
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [hasSubmitted, setHasSubmitted] = React.useState(false);
    const [submissionError, setSubmissionError] = React.useState("");

    const [radioValue, setRadioValue] = React.useState("parent");
    const checkboxValues = UIState.useGlobalState(gs => gs.checkboxValues);

    const onSubmit = data => {
        if (data.email) {
            return;
        }

        let parsedData = {
            email: data.em_,
            name: data.na_,
            phone: data.ph_,
            note: data.note,
            iam: radioValue,
            courses: checkboxValues
        };

        setIsSubmitting(true)

        APIRequest("form", null, { validateAndSubmitEmail: parsedData }).then((res) => {
            if (res.success === true) {
                //let emailAddr = res.result;

                /*let str = `
                     Hi! \r\n
                     I'm interested in learning more about Penelope Gifted. \r\n
                     I am a: ${radioValue} \r\n
                     I'm interested in: ${checkboxValues.join(', ')} \r\n`*/

                //let link = `mailto:${emailAddr}?&subject=${encodeURIComponent("Penelope Gifted Form Submission")}&body=${encodeURIComponent(str)}`

                //navigate(link)

                setHasSubmitted(true);
                onClose()
            } else {
                setSubmissionError(res.result);
            }
        })
    }

    const changeCheckboxValues = (e) => {
        UIState.setCheckboxValues(e)
    }

    useEffect(() => {
        setTimeout(() => onClose(), 500)
    }, [hasSubmitted])

    let pageData = UIState.useGlobalState(gs => gs.pageData);

    const isOpen = UIState.useGlobalState(gs => gs.showSignupModal);
    const onClose = UIState.onCloseSignupModal;

    return (
        <Chakra.Modal isOpen={isOpen} onClose={onClose}>
            <Chakra.ModalOverlay/>
            <Chakra.ModalContent maxWidth={{base: "100%", sm: "90%", md: "80%", lg: "70%", xl: "60%"}} mx={responsivePixel(10)}>
                <Chakra.Box p={responsivePixel(40)}>
                    <Chakra.Flex
                        alignItems="center"
                        flexDir="column"
                        mb="20px"
                    >
                        <HeadingText textAlign="center">Start your journey.</HeadingText>
                    </Chakra.Flex>
                    <Chakra.VStack
                        spacing="18px"
                        maxWidth="440px"
                        m="auto"
                        as="form"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Chakra.Divider/>

                        <ContactModalInput
                            label="Name"
                            type="text"
                            placeholder="Your Name"
                            autoComplete="name"
                            {...register("na_", {required: true, maxLength: 80})}
                            hasError={errors?.na_}
                        />
                        <ContactModalInput
                            label="E-mail"
                            type="email"
                            placeholder="Your Email"
                            autoComplete="email"
                            {...register("em_", {required: true, pattern: /^\S+@\S+$/i})}
                            hasError={errors?.em_}
                        />
                        <ContactModalInput
                            label="Phone"
                            type="text"
                            autoComplete="tel"
                            placeholder="Your phone (optional)"
                            {...register("ph_", {minLength: 4, maxLength: 20, pattern: /[0-9()\S+-]+?/g})}
                            hasError={errors?.ph_}
                        />
                        <Chakra.FormControl display="flex" alignItems="center" position="relative" width="100%">
                            <Chakra.FormLabel
                                as={BodyText}
                                scale={0.7}
                                pl="4px"
                                color="#000"
                                width={responsivePixel(120)}
                                pr="10px"
                            >
                                I am a:
                            </Chakra.FormLabel>
                            <Chakra.RadioGroup width="100%" value={radioValue} onChange={setRadioValue}>
                                <Chakra.Stack direction="column">
                                    <Chakra.Radio value="parent">Parent</Chakra.Radio>
                                    <Chakra.Radio value="educator">Educator</Chakra.Radio>
                                    <Chakra.Radio value="gifted-professional">Gifted Professional</Chakra.Radio>
                                </Chakra.Stack>
                            </Chakra.RadioGroup>
                        </Chakra.FormControl>

                        <Chakra.FormControl display="flex" alignItems="center" justifyContent="center" height="auto" position="relative" width="100%">
                            <Chakra.FormLabel
                                as={BodyText}
                                scale={0.7}
                                pl="4px"
                                color="#000"
                                width={responsivePixel(120)}
                                pr="10px"
                            >
                                Tell me about:
                            </Chakra.FormLabel>
                            <Chakra.Stack direction="column" width="100%" maxHeight="185px" overflowY="scroll" pr={3}>
                                <Chakra.CheckboxGroup defaultValue={checkboxValues}>
                                    {pageData.courses.map((course, i) => (
                                        <Chakra.Checkbox key={i} value={course.title} onChange={()=>changeCheckboxValues(course.title)}>{course.title}</Chakra.Checkbox>
                                    ))}
                                </Chakra.CheckboxGroup>
                            </Chakra.Stack>
                        </Chakra.FormControl>

                        <ContactModalInput
                            label="Notes"
                            type="textarea"
                            placeholder="Any info that may help us (optional)"
                            {...register("note", {})}
                        />
                        <ContactModalInput
                            type="email"
                            id="email"
                            name="email"
                            label="Email"
                            autoComplete="off"
                            placeholder="Your email (optional)"
                            {...register("email")}
                            isHoneypot
                        />
                        <Chakra.Divider pt={4}/>
                        <BodyText scale={0.5}>
                            <u>Use of Your Information</u><br/><br/>
                            If you contact us to learn more about Penelope Institute,
                            you are agreeing to submit your personal information to Penelope Institute. <br/><br/>
                            Penelope Institute and its staff will use your information only to:
                            <Chakra.OrderedList py={2}>
                                <Chakra.ListItem>Help you learn more about our organization and our offerings (which includes sending you email or other communications); and</Chakra.ListItem>
                                <Chakra.ListItem>Further develop our offerings.</Chakra.ListItem>
                            </Chakra.OrderedList>
                            We will not sell your personal information to any third party.
                        </BodyText>

                        <Chakra.Flex
                            flex="1"
                            alignItems="flex-end"
                            justify="flex-end"
                            pt={4}
                        >
                            <DarkButton disabled={!isValid} type="submit" isSubmitting={(isSubmitting && !hasSubmitted)} hasSubmitted={hasSubmitted}>Send</DarkButton>
                        </Chakra.Flex>
                    </Chakra.VStack>
                </Chakra.Box>

            </Chakra.ModalContent>
        </Chakra.Modal>


    )

  return (
    <Chakra.Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Chakra.ModalOverlay bg="rgba(0, 0, 0, 0.80)" />
      <Chakra.ModalContent
        borderRadius={{ base: 'none', xl: 'md' }}
      >
        <Chakra.ModalCloseButton
          size="xl"
          p="10px"
          m="30px"
        />
        <Chakra.ModalBody>

        </Chakra.ModalBody>
      </Chakra.ModalContent>
    </Chakra.Modal>
  )
}

export default ContactModal
