import React from 'react'
import * as Chakra from '@chakra-ui/react'

import EllipseIcon from './ellipse-icon'
import { Link } from 'gatsby'
import {useLocation} from "@reach/router";

interface Props {
  children: any
  to: string
  style?: any
  onClick?: any
}
const NavbarLink = ({ children, to, style, onClick }: Props) => {

    let {pathname} = useLocation();

  return (
      <Chakra.Link as={Link} onClick={onClick} to={to} color="#fff">
      <Chakra.Text pos="relative" {...style}>
        <Chakra.Text display={pathname === to ? 'block' : 'none'} pos="absolute" left="-16px">
          <EllipseIcon />
        </Chakra.Text>
        {children}
      </Chakra.Text>
      </Chakra.Link>
  )
}

export default NavbarLink
