import * as Chakra from "@chakra-ui/react";
import React from "react";

interface Props extends Chakra.TextProps {
    scale?: number
}

export const SubtitleText = ({scale = 1, ...props}: Props) => {

    let fontSize = 26;
    let lineHeight = 28;
    let factor = 1;

    if (scale) {
        factor = fontSize / (fontSize / scale);
    }

    return (
        <Chakra.Text
            fontFamily="subtitle"
            width="100%"
            fontSize={{base: `${fontSize * factor * 0.9}px`, md: `${fontSize * factor * 0.95}px`,  lg: `${fontSize * factor}px`, xl: `${fontSize * factor * 1.05}px`}}
            lineHeight={{base: `${lineHeight * factor * 0.9}px`, md: `${lineHeight * factor * 0.95}px`,  lg: `${lineHeight * factor}px`, xl: `${lineHeight * factor * 1.05}px`}}
            letterSpacing="0"
            {...props}
        />
    )
}
